<template>
  <div>
    <div class="all-height d-flex justify-center">
      <div class="all-width">
        <div class="d-flex align-center">
          <v-btn class="shadow-off mb-2" text
            ><v-icon left>mdi-arrow-left</v-icon> Back to Project list</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn class="shadow-off mb-4" text>
            <v-icon left>mdi-refresh</v-icon>Refresh</v-btn
          >
        </div>
      </div>
    </div>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="6" class="border-right px-0">
            <v-card class="shadow-off">
              <v-card-text class="pt-0 d-flex">
                <v-btn
                  fab
                  color="primary"
                  @click="addAreadialog = true"
                  elevation="0"
                  small
                  class="mr-4"
                  ><v-icon>mdi-plus</v-icon></v-btn
                >
                <v-text-field
                  label="Search"
                  outlined
                  hide-details
                  dense
                ></v-text-field>
                <v-btn icon class="ml-2"><v-icon>mdi-refresh</v-icon></v-btn>
              </v-card-text>

              <v-list>
                <v-list-group
                  v-for="item in items"
                  :key="item.title"
                  v-model="item.active"
                  no-action
                >
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="item.title"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <v-list-item>
                    <v-list-item-content @click="editArea(item)">
                      <v-list-item-title
                        v-text="item.subtitle"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog v-model="addAreadialog" max-width="600" persistent>
      <v-card class="">
        <v-card-text class="primary white--text d-flex align-center py-2 px-4">
          <span class="subtitle-1 white--text">Create New Area</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="addAreadialog = false" class="ml-2 white--text"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-text>
        <v-card-text class="mt-4 pt-4">
          <v-row>
            <v-col cols="12" class="my-0 py-1">
              <lb-string label="Area" v-model="area" />
            </v-col>
            <v-col cols="12" class="my-0 py-1">
              <lb-dropdown
                class="flex-grow-1"
                label="Sub Area"
                v-model="subarea"
                :items="subareaList"
                itemtext="name"
                itemvalue="_id"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text class="d-flex">
          <v-spacer></v-spacer>
          <v-btn small color="primary" @click="areaCreate()">Create</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      addAreadialog: false,
      area: null,
      subarea: null,
      subareaList: null,
      items: [
        {
          subtitle: "List Item",
          title: "Vendor Master",
        },
        {
          subtitle: "List Item",
          title: "Purchase Orders",
        },
        {
          subtitle: "List Item",
          title: "Human Resource",
        },
      ],
    };
  },
  created() {
    this.getAreaList();
  },
  methods: {
    getAreaList() {
      this.axios.post("/v2/conserve/area/list").then((dt) => {
        console.log(dt.data.data);
        this.subareaList = dt.data.data;
      });
    },
    areaCreate() {
      this.axios
        .post("/v2/conserve/area/add", {
          data: { name: this.area, group: this.subarea },
        })
        .then((dt) => {
          console.log(dt);
          this.getAreaList();
        });

      this.addAreadialog = false;
      (this.subarea = ""), (this.area = "");
    },
    editArea(item) {
      // console.log(item);
      // console.log(item.title);
      // console.log(item.items[0].title);
      this.subarea = item.items[0].title;
      this.area = item.title;
      this.addAreadialog = true;
    },
  },
};
</script>

<style>
</style>